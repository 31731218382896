.Receiver-container .add-receiver-btn {
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 22px; }

.Receiver-container .fee-container .fee-input-container {
  width: 150px;
  margin-right: 10px; }

.Receiver-container .fee-container .fee-payer-container {
  flex: 1; }
  .Receiver-container .fee-container .fee-payer-container .payer-label {
    font-weight: bold;
    color: #b3b3b3; }
  .Receiver-container .fee-container .fee-payer-container .available-ccc-text {
    font-size: 0.8rem;
    color: #b3b3b3; }

.Receiver-container .memo-container {
  margin-bottom: 20px; }
  .Receiver-container .memo-container .input-label {
    font-weight: bold;
    color: #b3b3b3; }
  .Receiver-container .memo-container textarea {
    border: 1px solid #93a8ea; }

.Receiver-container .Receiver-item {
  border-top: 2px solid #efefef;
  padding-top: 15px; }

.Receiver-container .Receiver-item:nth-child(1) {
  border-top: none;
  padding-top: 0; }
