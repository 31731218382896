.Asset-item {
  width: 306px;
  display: inline-block;
  padding: 11px 20px 11px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #efefef;
  background-color: #ffffff;
  display: inline-block;
  margin: 8px 8px 8px 8px;
  cursor: pointer; }
  @media (max-width: 767px) {
    .Asset-item {
      width: calc(100% - 16px); } }
  .Asset-item .asset-quantities {
    font-size: 1.4rem;
    color: #4f6a8e; }
  .Asset-item .info-icon {
    cursor: pointer;
    margin-left: 10px;
    font-size: 1.4rem;
    color: #8d8d8d;
    opacity: 0.4;
    line-height: 100%; }
    .Asset-item .info-icon:hover {
      opacity: 0.8; }
  .Asset-item.selected {
    background-color: #93a7ea52;
    color: white; }
    .Asset-item.selected .asset-quantities {
      color: white; }
    .Asset-item.selected .info-icon {
      color: white;
      opacity: 1; }
  .Asset-item .image-container {
    border-radius: 50%;
    overflow: hidden; }
    .Asset-item .image-container .asset-image {
      width: 48px;
      height: 48px; }
  .Asset-item .name-container {
    margin: 0px 12px 0px 12px;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    .Asset-item .name-container .asset-name {
      font-weight: 600;
      font-size: 1.2rem; }
