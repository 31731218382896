.Address-item {
  width: 100%;
  margin-bottom: 18px;
  cursor: pointer;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden; }
  @media (max-width: 767px) {
    .Address-item {
      margin-bottom: 18px; } }
  .Address-item .item-body {
    padding: 24px;
    padding-top: 14px;
    color: white;
    overflow: hidden; }
    .Address-item .item-body .address-text {
      opacity: 0.4; }
    .Address-item .item-body .address-name {
      font-size: 24px;
      font-weight: bold; }
    .Address-item .item-body.platform-type {
      background-color: #5ba5be; }
    .Address-item .item-body.asset-type {
      background-color: #4f6a8e; }
  .Address-item .platform-account {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 17px;
    padding-bottom: 17px;
    background-color: white; }
    .Address-item .platform-account .balance {
      font-weight: bold;
      font-size: 24px; }
    .Address-item .platform-account .asset-image {
      height: 37px;
      width: 37px;
      display: inline-block;
      margin-bottom: 0px;
      border-radius: 19px;
      margin-right: 12px;
      overflow: hidden;
      vertical-align: bottom; }
