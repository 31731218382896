.Header {
  background-color: white;
  height: 80px;
  border-bottom: 1px solid #efefef;
  position: relative;
  z-index: 5; }
  .Header .header-container {
    padding: 0 24px 0 24px; }
  @media (min-width: 1200px) {
    .Header .container {
      max-width: 1300px; } }
  .Header .logo {
    width: auto;
    height: 24px; }
  .Header .loading-bar {
    background-color: #93a8ea;
    height: 3px;
    position: absolute;
    bottom: -3px;
    width: 100%; }
