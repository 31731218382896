.Show-backup-phrase .text-1 {
  color: #8d8d8d; }

.Show-backup-phrase .warning-text {
  margin-top: 6px;
  color: #8d8d8d;
  font-weight: 600; }

.Show-backup-phrase .tips-container {
  margin-top: 15px; }
  .Show-backup-phrase .tips-container .tips-text {
    color: #8d8d8d;
    font-weight: 600; }
  .Show-backup-phrase .tips-container .text-2 {
    color: #8d8d8d; }

.Show-backup-phrase .backup-phrase-container {
  margin-top: 30px;
  margin-bottom: 26px; }
  .Show-backup-phrase .backup-phrase-container .backup-phrase-panel {
    height: 200px;
    border-radius: 5px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    border: 1px solid #93a8ea;
    padding: 30px;
    font-size: 1.3rem;
    color: #93a8ea;
    text-align: center;
    position: relative; }
    .Show-backup-phrase .backup-phrase-container .backup-phrase-panel .copy-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      border: 1px solid #93a8ea;
      padding: 2px 8px 2px 8px;
      border-radius: 5px;
      font-size: 0.9rem;
      background: #93a8ea;
      color: white;
      cursor: pointer; }
