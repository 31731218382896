.Receiver-item .max-btn {
  width: 55px;
  margin-bottom: 18px;
  margin-left: 5px; }

.Receiver-item .receiver-item-index {
  font-size: 1.2rem;
  color: #93a8ea;
  font-weight: bold; }

.Receiver-item .receiver-item-cancel {
  color: #d8d8d8;
  font-weight: bold;
  cursor: pointer; }
