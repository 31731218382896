.Address-container {
  position: relative;
  padding: 15px 0 15px 0; }
  .Address-container .back-btn {
    position: absolute;
    top: 20px;
    right: 0;
    color: white;
    opacity: 0.4;
    font-size: 1.8rem;
    cursor: pointer; }
    .Address-container .back-btn:hover {
      opacity: 0.8; }
  .Address-container .qr-container {
    width: 67px;
    height: 67px;
    padding: 5px;
    background-color: white; }
  .Address-container .name-address-container {
    flex: 1;
    color: white; }
    .Address-container .name-address-container .address-text {
      opacity: 0.6; }
    .Address-container .name-address-container .copy-btn {
      cursor: pointer; }
