.Send-CCC {
  padding: 50px;
  position: relative; }
  .Send-CCC .loading-container {
    height: 420px; }
  @media screen and (max-width: 872px) {
    .Send-CCC {
      z-index: 2;
      background-color: white;
      padding: 30px; }
    .Send-CCC {
      height: calc(100vh - 80px);
      overflow: scroll; }
    .Send-CCC .loading-container {
      height: calc(100vh - 80px); } }
  .Send-CCC .title {
    color: #93a8ea; }
  .Send-CCC .complete-container {
    height: 400px;
    font-size: 1.7rem;
    color: #93a8ea;
    font-weight: bold; }
  .Send-CCC .sending-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(44, 44, 44, 0.3); }
  .Send-CCC .balance-container .balance-text {
    font-size: 1.28rem;
    color: #8d8d8d;
    font-weight: bold; }
  .Send-CCC .balance-container .amount {
    font-size: 1.14rem; }
  .Send-CCC .cancel-icon-container {
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    cursor: pointer; }
    .Send-CCC .cancel-icon-container .cancel-icon {
      font-size: 2rem;
      color: #93a8ea; }
