.Login {
  position: relative; }
  .Login .title-container {
    padding-top: 100px; }
  .Login .logo-title {
    font-size: 3.5rem; }
  .Login .logo {
    width: 100px;
    height: auto; }
  .Login .login-form-container {
    margin-top: 50px; }
  .Login .open-different-btn-container {
    margin-top: 18px;
    margin-bottom: 36px;
    cursor: pointer; }
    .Login .open-different-btn-container .open-different-btn {
      font-size: 1.2rem;
      color: #8d8d8d;
      font-weight: 600;
      text-decoration: underline; }
  @media (max-width: 767px) {
    .Login .logo-title {
      font-size: 2rem; }
    .Login .logo {
      width: 80px;
      height: auto; }
    .Login .open-different-btn-container .open-different-btn {
      font-size: 1rem; } }
