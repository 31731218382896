.login-form {
  background-color: white;
  width: 100%;
  max-width: 520px;
  border-radius: 2px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  padding: 46px 52px 52px 46px; }
  @media (max-width: 767px) {
    .login-form {
      padding: 30px; } }
  .login-form .welcome-text {
    font-size: 1.4rem;
    color: #93a8ea; }
  .login-form .passphrase-input-container {
    margin-top: 25px;
    margin-bottom: 20px; }
  .login-form .sign-in-btn {
    width: 100%;
    height: 48px; }
