.Export-backup-popup .modal-header {
  border-bottom: none;
  padding: 20px; }
  .Export-backup-popup .modal-header .modal-title {
    color: #93a8ea; }
  .Export-backup-popup .modal-header button span {
    font-size: 2rem;
    color: #93a8ea; }

.Export-backup-popup .modal-body {
  padding: 0 20px 0 20px; }
  .Export-backup-popup .modal-body .passphrase-container {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    height: 200px;
    border: 1px solid #93a8ea;
    font-size: 1.14rem;
    color: #93a8ea;
    text-align: center;
    position: relative; }
    .Export-backup-popup .modal-body .passphrase-container .copy-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      border: 1px solid #93a8ea;
      padding: 2px 8px 2px 8px;
      border-radius: 5px;
      font-size: 0.9rem;
      background: #93a8ea;
      color: white;
      cursor: pointer; }
    .Export-backup-popup .modal-body .passphrase-container .passphrase-panel {
      width: 100%;
      height: 100%;
      padding: 30px; }
    .Export-backup-popup .modal-body .passphrase-container .disable-panel {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #efefef;
      font-size: 1rem;
      color: #b3b3b3;
      font-weight: bold;
      padding: 30px; }

.Export-backup-popup .modal-footer {
  border-top: none; }
