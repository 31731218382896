.Asset-detail {
  position: relative;
  background: linear-gradient(180deg, #4f6a8e 200px, transparent 0%); }
  .Asset-detail .panel {
    padding: 15px;
    max-width: 1084px;
    flex: 1;
    min-width: 0; }
  .Asset-detail .detail-history-container {
    border-radius: 5px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
    background-color: white;
    padding: 32px 52px 32px 52px; }
    @media (max-width: 767px) {
      .Asset-detail .detail-history-container {
        padding: 32px 30px 32px 30px; } }
    .Asset-detail .detail-history-container .asset-image {
      border-radius: 50%;
      overflow: hidden; }
    .Asset-detail .detail-history-container .name-quantity-container {
      min-width: 0;
      flex: 1; }
      .Asset-detail .detail-history-container .name-quantity-container .asset-type {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .Asset-detail .detail-history-container .name-quantity-container .total-text {
        font-weight: bold;
        color: #8d8d8d; }
      .Asset-detail .detail-history-container .name-quantity-container .quantity-text {
        font-size: 1.5rem;
        color: #4f6a8e;
        font-weight: bold; }
    .Asset-detail .detail-history-container .info-container {
      margin-bottom: 60px; }
      .Asset-detail .detail-history-container .info-container .row {
        margin-bottom: 12px; }
      .Asset-detail .detail-history-container .info-container [class*="col-"]:nth-child(1) {
        font-weight: 600;
        color: #8d8d8d; }
      .Asset-detail .detail-history-container .info-container [class*="col-"]:nth-child(2) {
        font-family: "Spoqa Han Sans", "Spoqa Han Sans JP", "Sans-serif";
        color: #b3b3b3; }
