.Send-asset {
  padding: 50px;
  position: relative; }
  .Send-asset .loading-container {
    height: 420px; }
  @media screen and (max-width: 872px) {
    .Send-asset {
      z-index: 2;
      background-color: white;
      padding: 30px; }
    .Send-asset {
      height: calc(100vh - 80px);
      overflow: scroll; }
    .Send-asset .loading-container {
      height: calc(100vh - 80px); } }
  .Send-asset .title {
    color: #93a8ea; }
  .Send-asset .complete-container {
    height: 400px;
    font-size: 1.7rem;
    color: #93a8ea;
    font-weight: bold; }
  .Send-asset .sending-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(44, 44, 44, 0.3); }
  .Send-asset .asset-info-item {
    margin-top: 26px; }
    .Send-asset .asset-info-item .asset-info-icon {
      width: 36px;
      height: 36px;
      overflow: hidden;
      border-radius: 50px; }
    .Send-asset .asset-info-item .name {
      font-size: 1.5rem;
      font-weight: 600; }
    .Send-asset .asset-info-item .quantity {
      color: #4f6a8e;
      font-size: 1.5rem;
      font-weight: bold; }
  .Send-asset .cancel-icon-container {
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    cursor: pointer; }
    .Send-asset .cancel-icon-container .cancel-icon {
      font-size: 2rem;
      color: #93a8ea; }
