.Asset-tx-item {
  border-bottom: 1px solid #d8d8d8; }
  .Asset-tx-item > div {
    padding: 18px; }
    @media (max-width: 767px) {
      .Asset-tx-item > div {
        padding: 8px; } }
  .Asset-tx-item .date-container {
    white-space: pre;
    color: #8d8d8d;
    font-weight: 400; }
    @media (max-width: 767px) {
      .Asset-tx-item .date-container {
        text-align: center; } }
  .Asset-tx-item .asset-info-container {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .Asset-tx-item .asset-info-container .asset-image {
      border-radius: 50%;
      overflow: hidden; }
    .Asset-tx-item .asset-info-container .asset-name {
      color: #b3b3b3;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1; }
    .Asset-tx-item .asset-info-container .transaction-hash {
      color: #8d8d8d;
      text-decoration: none; }
      .Asset-tx-item .asset-info-container .transaction-hash:hover {
        text-decoration: underline; }
  .Asset-tx-item .memo-container {
    margin-top: 8px; }
    .Asset-tx-item .memo-container .memo-text {
      color: #8d8d8d;
      font-size: 0.9rem; }
  .Asset-tx-item .balance-container {
    color: #8d8d8d;
    font-size: 1.3rem; }
    @media (max-width: 767px) {
      .Asset-tx-item .balance-container {
        font-size: 1.1rem; } }
  .Asset-tx-item .status-container {
    font-weight: 600;
    width: 80px;
    text-align: center; }
    .Asset-tx-item .status-container .pending {
      color: #ffd638; }
    .Asset-tx-item .status-container .confirmed {
      color: #75d08f; }
    .Asset-tx-item .status-container .failed {
      color: #ff5b5b; }
