.Input-passphrase .form-container {
  margin-top: 32px; }
  .Input-passphrase .form-container .form-check {
    font-size: 1rem;
    margin-bottom: 4px; }
    .Input-passphrase .form-container .form-check .form-check-label {
      cursor: pointer;
      color: #8d8d8d; }
      .Input-passphrase .form-container .form-check .form-check-label a {
        text-decoration: underline;
        color: #93a8ea; }
