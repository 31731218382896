.Restore-wallet .close-btn {
  margin-top: 100px; }
  @media (max-width: 767px) {
    .Restore-wallet .close-btn {
      margin-top: 30px; } }
  .Restore-wallet .close-btn .icon {
    width: 38px;
    height: 38px;
    color: #93a8ea;
    cursor: pointer; }

.Restore-wallet .title-container {
  margin-top: 42px;
  margin-bottom: 20px; }
  .Restore-wallet .title-container .title {
    font-size: 2rem;
    color: #93a8ea; }

.Restore-wallet .password-description {
  margin-top: 20px;
  color: #8d8d8d; }

.Restore-wallet .restore-content {
  max-width: 416px;
  margin-bottom: 60px; }
  .Restore-wallet .restore-content .description {
    color: #8d8d8d; }
  .Restore-wallet .restore-content .phrase-container {
    margin-top: 20px; }
    .Restore-wallet .restore-content .phrase-container .phrase-input {
      padding: 30px;
      width: 100%;
      height: 170px;
      resize: none;
      border-radius: 5px;
      box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
      background-color: #ffffff;
      border: 1px solid #93a8ea; }
  .Restore-wallet .restore-content .username-input-container {
    margin-top: 18px; }

.Restore-wallet .main-btn-container {
  margin-top: 28px; }
  .Restore-wallet .main-btn-container .main-btn {
    height: 48px;
    width: 100%; }
