.Select-key-file {
  position: relative;
  text-align: center;
  padding-bottom: 40px; }
  .Select-key-file .title-container {
    padding-top: 60px; }
  .Select-key-file .logo-title {
    font-size: 3.5rem; }
  .Select-key-file .welcome-text-container {
    margin-top: 18px;
    max-width: 400px;
    display: inline-block; }
    .Select-key-file .welcome-text-container .welcome-text {
      color: #8d8d8d; }
  .Select-key-file .logo {
    width: 100px;
    height: auto; }
  @media (max-width: 767px) {
    .Select-key-file .logo-title {
      font-size: 2rem; }
    .Select-key-file .logo {
      width: 80px;
      height: auto; } }
  .Select-key-file .button-container {
    margin-top: 22px; }
    .Select-key-file .button-container .button-item {
      width: 250px;
      border-radius: 5px;
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2);
      background-color: #ffffff;
      border: 1px solid #93a8ea;
      text-align: center;
      color: #93a8ea;
      margin-right: 10px;
      margin-left: 10px;
      padding: 34px 12px 34px 12px;
      cursor: pointer; }
      .Select-key-file .button-container .button-item:hover {
        background-color: #93a8ea;
        color: #ffffff; }
      .Select-key-file .button-container .button-item .icon {
        width: 78px;
        height: 78px; }
      .Select-key-file .button-container .button-item .text {
        margin-top: 20px;
        font-size: 1.2rem;
        line-height: 1.25;
        font-family: "Montserrat", sans-serif;
        font-weight: 700; }
      .Select-key-file .button-container .button-item .button-description {
        margin-top: 16px; }
      @media (max-width: 767px) {
        .Select-key-file .button-container .button-item {
          width: 150px; }
          .Select-key-file .button-container .button-item .icon {
            width: 40px;
            height: 40px; }
          .Select-key-file .button-container .button-item .text {
            font-size: 1rem; } }
