.Address-list {
  padding-bottom: 100px; }
  @media (min-width: 1200px) {
    .Address-list .container {
      max-width: 1300px; } }
  .Address-list .asset-address-container {
    margin-top: 60px; }
  .Address-list .platform-address-container {
    margin-top: 28px; }
  .Address-list .address-description {
    color: #8d8d8d; }
  .Address-list .add-address-btn,
  .Address-list .mint-asset-btn,
  .Address-list .buy-CCC-btn {
    border-radius: 5px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: #93a7ea3f;
    color: #93a8ea;
    font-weight: bold;
    width: 100%;
    margin-bottom: 18px;
    height: 108px;
    cursor: pointer; }
  .Address-list .add-address-btn {
    height: 78px; }
  .Address-list .mint-asset-btn,
  .Address-list .buy-CCC-btn {
    height: 70px; }
  .Address-list .restoring {
    font-weight: bold;
    color: #8d8d8d; }
  .Address-list .deco {
    width: 64px;
    height: 4px;
    margin-top: 29px;
    margin-bottom: 10px; }
  .Address-list .deco.platform-title-deco {
    background-color: #5ba5be; }
  .Address-list .deco.asset-title-deco {
    background-color: #4f6a8e; }
