@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,600,700,900");
@import url("//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css");
@import url("https://fonts.googleapis.com/css?family=Inconsolata");
@import url(~animate.css/animate.css);
html {
  font-size: 14px; }

body {
  font-family: "Montserrat", "Noto Sans KR", sans-serif; }

.mono {
  font-family: "Inconsolata", monospace; }

.number {
  font-family: "Spoqa Han Sans", "Spoqa Han Sans JP", "Sans-serif";
  font-weight: bold; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700; }

a {
  color: #2c2c2c; }
  a:hover {
    color: #2c2c2c;
    text-decoration: none; }

.grey {
  color: #8d8d8d; }

.grey2 {
  color: #d8d8d8; }

.grey3 {
  color: #efefef; }

body {
  margin: 0;
  padding: 0;
  background: #f9f9f9; }

.btn-primary,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: white;
  border: 1px solid #93a8ea;
  border-radius: 21px;
  color: #93a8ea;
  font-weight: bold; }
  .btn-primary.square,
  .btn-primary:active.square,
  .btn-primary:visited.square,
  .btn-primary:focus.square {
    border-radius: 0; }
  .btn-primary.selected,
  .btn-primary:active.selected,
  .btn-primary:visited.selected,
  .btn-primary:focus.selected {
    background-color: #93a8ea;
    color: white; }
    .btn-primary.selected:hover,
    .btn-primary:active.selected:hover,
    .btn-primary:visited.selected:hover,
    .btn-primary:focus.selected:hover {
      cursor: default; }
  .btn-primary:hover:enabled,
  .btn-primary:active:hover:enabled,
  .btn-primary:visited:hover:enabled,
  .btn-primary:focus:hover:enabled {
    background-color: #93a8ea;
    border: 1px solid #93a8ea;
    color: white; }
  .btn-primary.reverse,
  .btn-primary:active.reverse,
  .btn-primary:visited.reverse,
  .btn-primary:focus.reverse {
    background-color: #93a8ea;
    color: white;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2); }
    .btn-primary.reverse:hover,
    .btn-primary:active.reverse:hover,
    .btn-primary:visited.reverse:hover,
    .btn-primary:focus.reverse:hover {
      background-color: #6886e2;
      cursor: pointer; }
  .btn-primary:disabled,
  .btn-primary:active:disabled,
  .btn-primary:visited:disabled,
  .btn-primary:focus:disabled {
    background-color: white;
    color: #8d8d8d;
    border: 1px solid #8d8d8d;
    cursor: default; }
    .btn-primary:disabled:hover,
    .btn-primary:active:disabled:hover,
    .btn-primary:visited:disabled:hover,
    .btn-primary:focus:disabled:hover {
      background-color: white;
      color: #8d8d8d;
      border: 1px solid #8d8d8d;
      cursor: default; }

select.form-control {
  border: 1px solid #93a8ea; }

input.form-control[type="text"],
input.form-control[type="password"],
input.form-control[type="number"] {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #93a8ea;
  border-radius: 0;
  font-family: "Spoqa Han Sans", "Spoqa Han Sans JP", "Sans-serif";
  font-weight: bold;
  color: #8d8d8d;
  outline: none;
  box-shadow: none; }
  input.form-control[type="text"]::placeholder,
  input.form-control[type="password"]::placeholder,
  input.form-control[type="number"]::placeholder {
    color: #d8d8d8;
    opacity: 0.7; }
  input.form-control[type="text"]:-ms-input-placeholder,
  input.form-control[type="password"]:-ms-input-placeholder,
  input.form-control[type="number"]:-ms-input-placeholder {
    color: #d8d8d8; }
  input.form-control[type="text"]::-ms-input-placeholder,
  input.form-control[type="password"]::-ms-input-placeholder,
  input.form-control[type="number"]::-ms-input-placeholder {
    color: #d8d8d8; }
  input.form-control[type="text"].reverse,
  input.form-control[type="password"].reverse,
  input.form-control[type="number"].reverse {
    border-bottom: 1px solid #f9f9f9;
    color: #f9f9f9; }
    input.form-control[type="text"].reverse::placeholder,
    input.form-control[type="password"].reverse::placeholder,
    input.form-control[type="number"].reverse::placeholder {
      color: #f9f9f9;
      opacity: 0.7; }
    input.form-control[type="text"].reverse:-ms-input-placeholder,
    input.form-control[type="password"].reverse:-ms-input-placeholder,
    input.form-control[type="number"].reverse:-ms-input-placeholder {
      color: #f9f9f9; }
    input.form-control[type="text"].reverse::-ms-input-placeholder,
    input.form-control[type="password"].reverse::-ms-input-placeholder,
    input.form-control[type="number"].reverse::-ms-input-placeholder {
      color: #f9f9f9; }

.popover-select-list {
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 700; }
  .popover-select-list li {
    padding: 12px 24px 12px 24px;
    border-bottom: 1px solid #93a7ea4f;
    color: #93a8ea;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer; }
    .popover-select-list li:hover {
      background-color: #93a7ea42; }

.Toastify {
  font-family: "Montserrat", sans-serif;
  font-weight: 700; }
  .Toastify .Toastify__toast--error {
    background-color: #ff5b5b; }
  .Toastify .Toastify__toast--info {
    background-color: #93a8ea; }

.pagination {
  display: inline-flex; }
  .pagination .page-link {
    padding: 0px 8px 0px 8px;
    line-height: 28px;
    color: #b3b3b3;
    border: none;
    font-size: 1.3rem; }
    .pagination .page-link:hover {
      background-color: inherit; }
  .pagination .navigation-icon {
    color: #b3b3b3;
    font-size: 1.3rem;
    border-radius: 2px; }
  .pagination .page-item.active .page-link {
    background-color: inherit;
    color: #93a8ea;
    font-weight: bold; }
