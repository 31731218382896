.Confirm-backup-phrase .description {
  color: #8d8d8d;
  margin-bottom: 30px; }

.Confirm-backup-phrase .backup-phrase-input {
  height: 170px;
  border-radius: 5px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border: 1px solid #93a8ea;
  overflow: scroll;
  padding: 30px;
  font-size: 1.3rem;
  color: #93a8ea;
  text-align: center; }

.Confirm-backup-phrase .backup-phrase-button-container {
  margin-top: 26px;
  margin-bottom: 26px; }
  .Confirm-backup-phrase .backup-phrase-button-container .backup-phrase-btn {
    margin-right: 12px;
    margin-bottom: 12px; }
