.Validation-input {
  position: relative; }
  .Validation-input .validation-form {
    padding-right: 20px; }
  .Validation-input .label-text {
    color: #b3b3b3;
    font-weight: bold; }
    .Validation-input .label-text.reverse {
      color: white; }
  .Validation-input .error-icon {
    position: absolute;
    right: 0;
    bottom: 5px; }
  .Validation-input .error-text {
    position: absolute;
    right: 0;
    bottom: -15px;
    font-size: 10px;
    color: #ff5b5b; }
  .Validation-input .valid-icon {
    position: absolute;
    right: 0;
    bottom: 5px; }
