.Create-wallet .close-btn {
  margin-top: 100px; }
  @media (max-width: 767px) {
    .Create-wallet .close-btn {
      margin-top: 30px; } }
  .Create-wallet .close-btn .icon {
    width: 38px;
    height: 38px;
    color: #93a8ea;
    cursor: pointer; }

.Create-wallet .create-wallet-form-group {
  max-width: 416px; }

.Create-wallet .title-container {
  margin-top: 42px;
  margin-bottom: 20px; }
  .Create-wallet .title-container .title {
    font-size: 2rem;
    color: #93a8ea; }

.Create-wallet .main-btn {
  height: 48px;
  width: 100%; }

.Create-wallet .dot-indicator-container {
  margin-top: 69px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .Create-wallet .dot-indicator-container {
      margin-top: 45px; } }
  .Create-wallet .dot-indicator-container .indicator-icon {
    margin-right: 10px; }
    .Create-wallet .dot-indicator-container .indicator-icon.active {
      color: #93a8ea; }
    .Create-wallet .dot-indicator-container .indicator-icon.inactive {
      color: #d8d8d8; }
