.Account {
  position: relative;
  background: linear-gradient(180deg, #5ba5be 200px, transparent 0%); }
  .Account .left-panel {
    padding: 15px;
    max-width: 1084px;
    flex: 1;
    min-width: 0; }
  .Account .element-container {
    background-color: white;
    padding-left: 52px;
    padding-right: 52px;
    padding-top: 30px;
    padding-bottom: 42px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
    border-radius: 5px; }
    @media (max-width: 767px) {
      .Account .element-container {
        padding-left: 30px;
        padding-right: 30px; } }
    .Account .element-container .ccc-text {
      color: #5ba5be;
      font-size: 3rem; }
      @media (max-width: 767px) {
        .Account .element-container .ccc-text {
          font-size: 2rem; } }
    .Account .element-container .send-btn {
      width: 168px; }
  .Account .right-container {
    margin: 28px 12px 28px 12px;
    width: 412px; }
    .Account .right-container .right-panel {
      box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
      background-color: #ffffff;
      border-radius: 5px; }
    @media screen and (max-width: 872px) {
      .Account .right-container {
        position: absolute;
        width: 100%;
        margin: 0 0 0 0; }
        .Account .right-container .right-panel {
          height: calc(100vh - 80px);
          border-radius: 0;
          box-shadow: none;
          overflow: scroll; } }
