.Pay-tx-item {
  border-bottom: 1px solid #d8d8d8; }
  .Pay-tx-item > div {
    padding: 18px; }
    @media (max-width: 767px) {
      .Pay-tx-item > div {
        padding: 8px; } }
  .Pay-tx-item .date-container {
    white-space: pre;
    color: #8d8d8d;
    font-weight: 400; }
    @media (max-width: 767px) {
      .Pay-tx-item .date-container {
        text-align: center; } }
  .Pay-tx-item .pay-info-container {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .Pay-tx-item .pay-info-container .transaction-hash {
      color: #8d8d8d;
      text-decoration: none; }
      .Pay-tx-item .pay-info-container .transaction-hash:hover {
        text-decoration: underline; }
  .Pay-tx-item .balance-container {
    color: #8d8d8d;
    font-size: 1.3rem; }
    @media (max-width: 767px) {
      .Pay-tx-item .balance-container {
        font-size: 1.1rem; } }
  .Pay-tx-item .status-container {
    font-weight: 600;
    width: 80px;
    text-align: center; }
    .Pay-tx-item .status-container .pending {
      color: #ffd638; }
    .Pay-tx-item .status-container .confirmed {
      color: #75d08f; }
    .Pay-tx-item .status-container .failed {
      color: #ff5b5b; }
