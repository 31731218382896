@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,600,700,900);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url(https://fonts.googleapis.com/css?family=Inconsolata);
.Address-container {
  position: relative;
  padding: 15px 0 15px 0; }
  .Address-container .back-btn {
    position: absolute;
    top: 20px;
    right: 0;
    color: white;
    opacity: 0.4;
    font-size: 1.8rem;
    cursor: pointer; }
    .Address-container .back-btn:hover {
      opacity: 0.8; }
  .Address-container .qr-container {
    width: 67px;
    height: 67px;
    padding: 5px;
    background-color: white; }
  .Address-container .name-address-container {
    flex: 1 1;
    color: white; }
    .Address-container .name-address-container .address-text {
      opacity: 0.6; }
    .Address-container .name-address-container .copy-btn {
      cursor: pointer; }

.Asset-tx-history .pagination-container {
  margin-top: 20px;
  text-align: center; }

.Pay-tx-item {
  border-bottom: 1px solid #d8d8d8; }
  .Pay-tx-item > div {
    padding: 18px; }
    @media (max-width: 767px) {
      .Pay-tx-item > div {
        padding: 8px; } }
  .Pay-tx-item .date-container {
    white-space: pre;
    color: #8d8d8d;
    font-weight: 400; }
    @media (max-width: 767px) {
      .Pay-tx-item .date-container {
        text-align: center; } }
  .Pay-tx-item .pay-info-container {
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .Pay-tx-item .pay-info-container .transaction-hash {
      color: #8d8d8d;
      text-decoration: none; }
      .Pay-tx-item .pay-info-container .transaction-hash:hover {
        text-decoration: underline; }
  .Pay-tx-item .balance-container {
    color: #8d8d8d;
    font-size: 1.3rem; }
    @media (max-width: 767px) {
      .Pay-tx-item .balance-container {
        font-size: 1.1rem; } }
  .Pay-tx-item .status-container {
    font-weight: 600;
    width: 80px;
    text-align: center; }
    .Pay-tx-item .status-container .pending {
      color: #ffd638; }
    .Pay-tx-item .status-container .confirmed {
      color: #75d08f; }
    .Pay-tx-item .status-container .failed {
      color: #ff5b5b; }

.Account {
  position: relative;
  background: linear-gradient(180deg, #5ba5be 200px, transparent 0%); }
  .Account .left-panel {
    padding: 15px;
    max-width: 1084px;
    flex: 1 1;
    min-width: 0; }
  .Account .element-container {
    background-color: white;
    padding-left: 52px;
    padding-right: 52px;
    padding-top: 30px;
    padding-bottom: 42px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
    border-radius: 5px; }
    @media (max-width: 767px) {
      .Account .element-container {
        padding-left: 30px;
        padding-right: 30px; } }
    .Account .element-container .ccc-text {
      color: #5ba5be;
      font-size: 3rem; }
      @media (max-width: 767px) {
        .Account .element-container .ccc-text {
          font-size: 2rem; } }
    .Account .element-container .send-btn {
      width: 168px; }
  .Account .right-container {
    margin: 28px 12px 28px 12px;
    width: 412px; }
    .Account .right-container .right-panel {
      box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
      background-color: #ffffff;
      border-radius: 5px; }
    @media screen and (max-width: 872px) {
      .Account .right-container {
        position: absolute;
        width: 100%;
        margin: 0 0 0 0; }
        .Account .right-container .right-panel {
          height: calc(100vh - 80px);
          border-radius: 0;
          box-shadow: none;
          overflow: scroll; } }

.CCCReceiver-container .add-receiver-btn {
  padding-left: 12px;
  padding-right: 12px; }

.Tooltip-label {
  display: inline; }
  .Tooltip-label .question-circle {
    color: #b3b3b3;
    cursor: pointer; }

.Validation-input {
  position: relative; }
  .Validation-input .validation-form {
    padding-right: 20px; }
  .Validation-input .label-text {
    color: #b3b3b3;
    font-weight: bold; }
    .Validation-input .label-text.reverse {
      color: white; }
  .Validation-input .error-icon {
    position: absolute;
    right: 0;
    bottom: 5px; }
  .Validation-input .error-text {
    position: absolute;
    right: 0;
    bottom: -15px;
    font-size: 10px;
    color: #ff5b5b; }
  .Validation-input .valid-icon {
    position: absolute;
    right: 0;
    bottom: 5px; }

.CCCReceiver-item .max-btn {
  width: 55px;
  margin-bottom: 18px;
  margin-left: 5px; }

.CCCReceiver-item .receiver-item-index {
  font-size: 1.2rem;
  color: #93a8ea;
  font-weight: bold; }

.Send-CCC {
  padding: 50px;
  position: relative; }
  .Send-CCC .loading-container {
    height: 420px; }
  @media screen and (max-width: 872px) {
    .Send-CCC {
      z-index: 2;
      background-color: white;
      padding: 30px; }
    .Send-CCC {
      height: calc(100vh - 80px);
      overflow: scroll; }
    .Send-CCC .loading-container {
      height: calc(100vh - 80px); } }
  .Send-CCC .title {
    color: #93a8ea; }
  .Send-CCC .complete-container {
    height: 400px;
    font-size: 1.7rem;
    color: #93a8ea;
    font-weight: bold; }
  .Send-CCC .sending-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(44, 44, 44, 0.3); }
  .Send-CCC .balance-container .balance-text {
    font-size: 1.28rem;
    color: #8d8d8d;
    font-weight: bold; }
  .Send-CCC .balance-container .amount {
    font-size: 1.14rem; }
  .Send-CCC .cancel-icon-container {
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    cursor: pointer; }
    .Send-CCC .cancel-icon-container .cancel-icon {
      font-size: 2rem;
      color: #93a8ea; }

.Address-item {
  width: 100%;
  margin-bottom: 18px;
  cursor: pointer;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden; }
  @media (max-width: 767px) {
    .Address-item {
      margin-bottom: 18px; } }
  .Address-item .item-body {
    padding: 24px;
    padding-top: 14px;
    color: white;
    overflow: hidden; }
    .Address-item .item-body .address-text {
      opacity: 0.4; }
    .Address-item .item-body .address-name {
      font-size: 24px;
      font-weight: bold; }
    .Address-item .item-body.platform-type {
      background-color: #5ba5be; }
    .Address-item .item-body.asset-type {
      background-color: #4f6a8e; }
  .Address-item .platform-account {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 17px;
    padding-bottom: 17px;
    background-color: white; }
    .Address-item .platform-account .balance {
      font-weight: bold;
      font-size: 24px; }
    .Address-item .platform-account .asset-image {
      height: 37px;
      width: 37px;
      display: inline-block;
      margin-bottom: 0px;
      border-radius: 19px;
      margin-right: 12px;
      overflow: hidden;
      vertical-align: bottom; }

.Address-list {
  padding-bottom: 100px; }
  @media (min-width: 1200px) {
    .Address-list .container {
      max-width: 1300px; } }
  .Address-list .asset-address-container {
    margin-top: 60px; }
  .Address-list .platform-address-container {
    margin-top: 28px; }
  .Address-list .address-description {
    color: #8d8d8d; }
  .Address-list .add-address-btn,
  .Address-list .mint-asset-btn,
  .Address-list .buy-CCC-btn {
    border-radius: 5px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: #93a7ea3f;
    color: #93a8ea;
    font-weight: bold;
    width: 100%;
    margin-bottom: 18px;
    height: 108px;
    cursor: pointer; }
  .Address-list .add-address-btn {
    height: 78px; }
  .Address-list .mint-asset-btn,
  .Address-list .buy-CCC-btn {
    height: 70px; }
  .Address-list .restoring {
    font-weight: bold;
    color: #8d8d8d; }
  .Address-list .deco {
    width: 64px;
    height: 4px;
    margin-top: 29px;
    margin-bottom: 10px; }
  .Address-list .deco.platform-title-deco {
    background-color: #5ba5be; }
  .Address-list .deco.asset-title-deco {
    background-color: #4f6a8e; }

.Asset-tx-history .empty {
  color: #d8d8d8;
  font-size: 1.14rem;
  font-weight: bold; }

.Asset-tx-history .pagination-container {
  margin-top: 20px;
  text-align: center; }

.Asset-tx-item {
  border-bottom: 1px solid #d8d8d8; }
  .Asset-tx-item > div {
    padding: 18px; }
    @media (max-width: 767px) {
      .Asset-tx-item > div {
        padding: 8px; } }
  .Asset-tx-item .date-container {
    white-space: pre;
    color: #8d8d8d;
    font-weight: 400; }
    @media (max-width: 767px) {
      .Asset-tx-item .date-container {
        text-align: center; } }
  .Asset-tx-item .asset-info-container {
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .Asset-tx-item .asset-info-container .asset-image {
      border-radius: 50%;
      overflow: hidden; }
    .Asset-tx-item .asset-info-container .asset-name {
      color: #b3b3b3;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1 1; }
    .Asset-tx-item .asset-info-container .transaction-hash {
      color: #8d8d8d;
      text-decoration: none; }
      .Asset-tx-item .asset-info-container .transaction-hash:hover {
        text-decoration: underline; }
  .Asset-tx-item .memo-container {
    margin-top: 8px; }
    .Asset-tx-item .memo-container .memo-text {
      color: #8d8d8d;
      font-size: 0.9rem; }
  .Asset-tx-item .balance-container {
    color: #8d8d8d;
    font-size: 1.3rem; }
    @media (max-width: 767px) {
      .Asset-tx-item .balance-container {
        font-size: 1.1rem; } }
  .Asset-tx-item .status-container {
    font-weight: 600;
    width: 80px;
    text-align: center; }
    .Asset-tx-item .status-container .pending {
      color: #ffd638; }
    .Asset-tx-item .status-container .confirmed {
      color: #75d08f; }
    .Asset-tx-item .status-container .failed {
      color: #ff5b5b; }

.Asset-detail {
  position: relative;
  background: linear-gradient(180deg, #4f6a8e 200px, transparent 0%); }
  .Asset-detail .panel {
    padding: 15px;
    max-width: 1084px;
    flex: 1 1;
    min-width: 0; }
  .Asset-detail .detail-history-container {
    border-radius: 5px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
    background-color: white;
    padding: 32px 52px 32px 52px; }
    @media (max-width: 767px) {
      .Asset-detail .detail-history-container {
        padding: 32px 30px 32px 30px; } }
    .Asset-detail .detail-history-container .asset-image {
      border-radius: 50%;
      overflow: hidden; }
    .Asset-detail .detail-history-container .name-quantity-container {
      min-width: 0;
      flex: 1 1; }
      .Asset-detail .detail-history-container .name-quantity-container .asset-type {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .Asset-detail .detail-history-container .name-quantity-container .total-text {
        font-weight: bold;
        color: #8d8d8d; }
      .Asset-detail .detail-history-container .name-quantity-container .quantity-text {
        font-size: 1.5rem;
        color: #4f6a8e;
        font-weight: bold; }
    .Asset-detail .detail-history-container .info-container {
      margin-bottom: 60px; }
      .Asset-detail .detail-history-container .info-container .row {
        margin-bottom: 12px; }
      .Asset-detail .detail-history-container .info-container [class*="col-"]:nth-child(1) {
        font-weight: 600;
        color: #8d8d8d; }
      .Asset-detail .detail-history-container .info-container [class*="col-"]:nth-child(2) {
        font-family: "Spoqa Han Sans", "Spoqa Han Sans JP", "Sans-serif";
        color: #b3b3b3; }

.Asset-item {
  width: 306px;
  display: inline-block;
  padding: 11px 20px 11px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #efefef;
  background-color: #ffffff;
  display: inline-block;
  margin: 8px 8px 8px 8px;
  cursor: pointer; }
  @media (max-width: 767px) {
    .Asset-item {
      width: calc(100% - 16px); } }
  .Asset-item .asset-quantities {
    font-size: 1.4rem;
    color: #4f6a8e; }
  .Asset-item .info-icon {
    cursor: pointer;
    margin-left: 10px;
    font-size: 1.4rem;
    color: #8d8d8d;
    opacity: 0.4;
    line-height: 100%; }
    .Asset-item .info-icon:hover {
      opacity: 0.8; }
  .Asset-item.selected {
    background-color: #93a7ea52;
    color: white; }
    .Asset-item.selected .asset-quantities {
      color: white; }
    .Asset-item.selected .info-icon {
      color: white;
      opacity: 1; }
  .Asset-item .image-container {
    border-radius: 50%;
    overflow: hidden; }
    .Asset-item .image-container .asset-image {
      width: 48px;
      height: 48px; }
  .Asset-item .name-container {
    margin: 0px 12px 0px 12px;
    flex: 1 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    .Asset-item .name-container .asset-name {
      font-weight: 600;
      font-size: 1.2rem; }

.Asset-list {
  position: relative;
  background: linear-gradient(180deg, #4f6a8e 200px, transparent 0%); }
  .Asset-list .empty {
    color: #d8d8d8;
    font-size: 1.14rem;
    font-weight: bold; }
  .Asset-list .left-panel {
    padding: 15px;
    max-width: 1084px;
    flex: 1 1;
    min-width: 0; }
  .Asset-list .asset-item-container {
    margin: -8px; }
  .Asset-list .element-container {
    background-color: white;
    padding-left: 52px;
    padding-right: 52px;
    padding-top: 30px;
    padding-bottom: 42px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
    border-radius: 5px; }
    @media (max-width: 767px) {
      .Asset-list .element-container {
        padding-left: 30px;
        padding-right: 30px; } }
  .Asset-list .right-container {
    margin: 28px 12px 28px 12px;
    width: 412px; }
    .Asset-list .right-container .right-panel {
      box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
      background-color: #ffffff;
      border-radius: 5px; }
    @media screen and (max-width: 872px) {
      .Asset-list .right-container {
        position: absolute;
        width: 100%;
        margin: 0 0 0 0; }
        .Asset-list .right-container .right-panel {
          height: calc(100vh - 80px);
          border-radius: 0;
          box-shadow: none;
          overflow: scroll; } }

.Receiver-container .add-receiver-btn {
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 22px; }

.Receiver-container .fee-container .fee-input-container {
  width: 150px;
  margin-right: 10px; }

.Receiver-container .fee-container .fee-payer-container {
  flex: 1 1; }
  .Receiver-container .fee-container .fee-payer-container .payer-label {
    font-weight: bold;
    color: #b3b3b3; }
  .Receiver-container .fee-container .fee-payer-container .available-ccc-text {
    font-size: 0.8rem;
    color: #b3b3b3; }

.Receiver-container .memo-container {
  margin-bottom: 20px; }
  .Receiver-container .memo-container .input-label {
    font-weight: bold;
    color: #b3b3b3; }
  .Receiver-container .memo-container textarea {
    border: 1px solid #93a8ea; }

.Receiver-container .Receiver-item {
  border-top: 2px solid #efefef;
  padding-top: 15px; }

.Receiver-container .Receiver-item:nth-child(1) {
  border-top: none;
  padding-top: 0; }

.Receiver-item .max-btn {
  width: 55px;
  margin-bottom: 18px;
  margin-left: 5px; }

.Receiver-item .receiver-item-index {
  font-size: 1.2rem;
  color: #93a8ea;
  font-weight: bold; }

.Receiver-item .receiver-item-cancel {
  color: #d8d8d8;
  font-weight: bold;
  cursor: pointer; }

.Send-asset {
  padding: 50px;
  position: relative; }
  .Send-asset .loading-container {
    height: 420px; }
  @media screen and (max-width: 872px) {
    .Send-asset {
      z-index: 2;
      background-color: white;
      padding: 30px; }
    .Send-asset {
      height: calc(100vh - 80px);
      overflow: scroll; }
    .Send-asset .loading-container {
      height: calc(100vh - 80px); } }
  .Send-asset .title {
    color: #93a8ea; }
  .Send-asset .complete-container {
    height: 400px;
    font-size: 1.7rem;
    color: #93a8ea;
    font-weight: bold; }
  .Send-asset .sending-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(44, 44, 44, 0.3); }
  .Send-asset .asset-info-item {
    margin-top: 26px; }
    .Send-asset .asset-info-item .asset-info-icon {
      width: 36px;
      height: 36px;
      overflow: hidden;
      border-radius: 50px; }
    .Send-asset .asset-info-item .name {
      font-size: 1.5rem;
      font-weight: 600; }
    .Send-asset .asset-info-item .quantity {
      color: #4f6a8e;
      font-size: 1.5rem;
      font-weight: bold; }
  .Send-asset .cancel-icon-container {
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    cursor: pointer; }
    .Send-asset .cancel-icon-container .cancel-icon {
      font-size: 2rem;
      color: #93a8ea; }

.Confirm-backup-phrase .description {
  color: #8d8d8d;
  margin-bottom: 30px; }

.Confirm-backup-phrase .backup-phrase-input {
  height: 170px;
  border-radius: 5px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border: 1px solid #93a8ea;
  overflow: scroll;
  padding: 30px;
  font-size: 1.3rem;
  color: #93a8ea;
  text-align: center; }

.Confirm-backup-phrase .backup-phrase-button-container {
  margin-top: 26px;
  margin-bottom: 26px; }
  .Confirm-backup-phrase .backup-phrase-button-container .backup-phrase-btn {
    margin-right: 12px;
    margin-bottom: 12px; }

.Create-wallet .close-btn {
  margin-top: 100px; }
  @media (max-width: 767px) {
    .Create-wallet .close-btn {
      margin-top: 30px; } }
  .Create-wallet .close-btn .icon {
    width: 38px;
    height: 38px;
    color: #93a8ea;
    cursor: pointer; }

.Create-wallet .create-wallet-form-group {
  max-width: 416px; }

.Create-wallet .title-container {
  margin-top: 42px;
  margin-bottom: 20px; }
  .Create-wallet .title-container .title {
    font-size: 2rem;
    color: #93a8ea; }

.Create-wallet .main-btn {
  height: 48px;
  width: 100%; }

.Create-wallet .dot-indicator-container {
  margin-top: 69px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .Create-wallet .dot-indicator-container {
      margin-top: 45px; } }
  .Create-wallet .dot-indicator-container .indicator-icon {
    margin-right: 10px; }
    .Create-wallet .dot-indicator-container .indicator-icon.active {
      color: #93a8ea; }
    .Create-wallet .dot-indicator-container .indicator-icon.inactive {
      color: #d8d8d8; }

.Input-passphrase .form-container {
  margin-top: 32px; }
  .Input-passphrase .form-container .form-check {
    font-size: 1rem;
    margin-bottom: 4px; }
    .Input-passphrase .form-container .form-check .form-check-label {
      cursor: pointer;
      color: #8d8d8d; }
      .Input-passphrase .form-container .form-check .form-check-label a {
        text-decoration: underline;
        color: #93a8ea; }

.Show-backup-phrase .text-1 {
  color: #8d8d8d; }

.Show-backup-phrase .warning-text {
  margin-top: 6px;
  color: #8d8d8d;
  font-weight: 600; }

.Show-backup-phrase .tips-container {
  margin-top: 15px; }
  .Show-backup-phrase .tips-container .tips-text {
    color: #8d8d8d;
    font-weight: 600; }
  .Show-backup-phrase .tips-container .text-2 {
    color: #8d8d8d; }

.Show-backup-phrase .backup-phrase-container {
  margin-top: 30px;
  margin-bottom: 26px; }
  .Show-backup-phrase .backup-phrase-container .backup-phrase-panel {
    height: 200px;
    border-radius: 5px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    border: 1px solid #93a8ea;
    padding: 30px;
    font-size: 1.3rem;
    color: #93a8ea;
    text-align: center;
    position: relative; }
    .Show-backup-phrase .backup-phrase-container .backup-phrase-panel .copy-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      border: 1px solid #93a8ea;
      padding: 2px 8px 2px 8px;
      border-radius: 5px;
      font-size: 0.9rem;
      background: #93a8ea;
      color: white;
      cursor: pointer; }

.Footer {
  padding: 60px 0px 50px 0px;
  background-color: #123447;
  color: white; }
  @media (min-width: 1200px) {
    .Footer .container {
      max-width: 1300px; } }
  .Footer a {
    color: white; }
    .Footer a:hover {
      color: white; }
  .Footer .left-panel .left-menu-list {
    list-style: none; }
    .Footer .left-panel .left-menu-list .list-inline-item {
      margin-right: 16px !important; }
    @media (max-width: 991px) {
      .Footer .left-panel .left-menu-list .list-inline-item {
        display: block; } }
  .Footer .right-panel {
    text-align: right; }
    @media (max-width: 1199px) {
      .Footer .right-panel {
        margin-top: 30px;
        text-align: left; } }
    .Footer .right-panel .right-menu-list {
      list-style: none; }
      .Footer .right-panel .right-menu-list .list-inline-item {
        margin-right: 16px !important; }
      .Footer .right-panel .right-menu-list .language-selector {
        color: white;
        background-color: #123447;
        width: 100px;
        height: 28px; }
      @media (max-width: 991px) {
        .Footer .right-panel .right-menu-list .language-selector-container {
          margin-top: 30px;
          display: block; } }

.Header {
  background-color: white;
  height: 80px;
  border-bottom: 1px solid #efefef;
  position: relative;
  z-index: 5; }
  .Header .header-container {
    padding: 0 24px 0 24px; }
  @media (min-width: 1200px) {
    .Header .container {
      max-width: 1300px; } }
  .Header .logo {
    width: auto;
    height: 24px; }
  .Header .loading-bar {
    background-color: #93a8ea;
    height: 3px;
    position: absolute;
    bottom: -3px;
    width: 100%; }

.Export-backup-popup .modal-header {
  border-bottom: none;
  padding: 20px; }
  .Export-backup-popup .modal-header .modal-title {
    color: #93a8ea; }
  .Export-backup-popup .modal-header button span {
    font-size: 2rem;
    color: #93a8ea; }

.Export-backup-popup .modal-body {
  padding: 0 20px 0 20px; }
  .Export-backup-popup .modal-body .passphrase-container {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    height: 200px;
    border: 1px solid #93a8ea;
    font-size: 1.14rem;
    color: #93a8ea;
    text-align: center;
    position: relative; }
    .Export-backup-popup .modal-body .passphrase-container .copy-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      border: 1px solid #93a8ea;
      padding: 2px 8px 2px 8px;
      border-radius: 5px;
      font-size: 0.9rem;
      background: #93a8ea;
      color: white;
      cursor: pointer; }
    .Export-backup-popup .modal-body .passphrase-container .passphrase-panel {
      width: 100%;
      height: 100%;
      padding: 30px; }
    .Export-backup-popup .modal-body .passphrase-container .disable-panel {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #efefef;
      font-size: 1rem;
      color: #b3b3b3;
      font-weight: bold;
      padding: 30px; }

.Export-backup-popup .modal-footer {
  border-top: none; }

.Menu-button {
  border-radius: 24px;
  color: #93a8ea;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 20px 10px 20px; }
  .Menu-button:hover {
    background-color: #93a7ea42; }

.Network-button {
  border-radius: 24px;
  color: #93a8ea;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 20px 10px 20px; }
  .Network-button:hover {
    background-color: #93a7ea42; }

.Login {
  position: relative; }
  .Login .title-container {
    padding-top: 100px; }
  .Login .logo-title {
    font-size: 3.5rem; }
  .Login .logo {
    width: 100px;
    height: auto; }
  .Login .login-form-container {
    margin-top: 50px; }
  .Login .open-different-btn-container {
    margin-top: 18px;
    margin-bottom: 36px;
    cursor: pointer; }
    .Login .open-different-btn-container .open-different-btn {
      font-size: 1.2rem;
      color: #8d8d8d;
      font-weight: 600;
      text-decoration: underline; }
  @media (max-width: 767px) {
    .Login .logo-title {
      font-size: 2rem; }
    .Login .logo {
      width: 80px;
      height: auto; }
    .Login .open-different-btn-container .open-different-btn {
      font-size: 1rem; } }

.login-form {
  background-color: white;
  width: 100%;
  max-width: 520px;
  border-radius: 2px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  padding: 46px 52px 52px 46px; }
  @media (max-width: 767px) {
    .login-form {
      padding: 30px; } }
  .login-form .welcome-text {
    font-size: 1.4rem;
    color: #93a8ea; }
  .login-form .passphrase-input-container {
    margin-top: 25px;
    margin-bottom: 20px; }
  .login-form .sign-in-btn {
    width: 100%;
    height: 48px; }

.Mint-asset {
  padding: 50px;
  position: relative; }
  @media screen and (max-width: 872px) {
    .Mint-asset {
      padding: 30px 0px 30px 0px; } }
  .Mint-asset .page-container {
    width: 100%;
    max-width: 450px;
    margin-bottom: 18px; }
  .Mint-asset .mint-container {
    background-color: white;
    padding: 30px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
    border-radius: 5px; }
  .Mint-asset .title {
    color: #93a8ea; }
  .Mint-asset .mint-description {
    color: #8d8d8d; }
  .Mint-asset .asset-info-container {
    margin-top: 30px; }
  .Mint-asset .description-area {
    border: 1px solid #93a8ea; }
  .Mint-asset .icon-url-input-container {
    flex: 1 1; }
  .Mint-asset .select-address-container {
    margin-top: 25px;
    margin-bottom: 25px; }
    .Mint-asset .select-address-container .select-address-label {
      color: #b3b3b3;
      font-weight: bold; }
    .Mint-asset .select-address-container select {
      border: 1px solid #93a8ea;
      margin-top: 8px; }
  .Mint-asset .loading-text,
  .Mint-asset .no-address-label {
    line-height: 60px;
    font-weight: bold;
    color: #8d8d8d; }
  .Mint-asset .preview-container {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin-left: 12px;
    margin-top: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border: 1px solid #93a8ea; }
  .Mint-asset .fee-container .fee-input-container {
    width: 150px;
    margin-right: 10px; }
  .Mint-asset .fee-container .fee-payer-container {
    flex: 1 1; }
    .Mint-asset .fee-container .fee-payer-container .available-ccc-text {
      font-size: 0.8rem;
      color: #b3b3b3; }
  .Mint-asset .input-label {
    font-weight: bold;
    color: #b3b3b3; }
  .Mint-asset .complete-container {
    height: 400px;
    font-size: 1.7rem;
    color: #93a8ea;
    font-weight: bold; }
  .Mint-asset .minting-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(44, 44, 44, 0.3); }
  .Mint-asset .back-icon-container {
    cursor: pointer; }
    .Mint-asset .back-icon-container .back-icon {
      font-size: 2rem;
      color: #93a8ea; }

#Not-found {
  position: relative;
  height: calc(100vh - 80px); }
  #Not-found .not-found {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  #Not-found .not-found {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center; }
  #Not-found .not-found .not-found-404 {
    position: relative;
    height: 240px; }
  #Not-found .not-found .not-found-404 h1 {
    font-family: "Montserrat", sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 252px;
    font-weight: 900;
    margin: 0px;
    color: #262626;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px; }
  #Not-found .not-found .not-found-404 h1 > span {
    text-shadow: -8px 0px 0px #fff; }
  #Not-found .not-found .not-found-404 h3 {
    font-family: "Cabin", sans-serif;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #262626;
    margin: 0px;
    letter-spacing: 3px;
    padding-left: 6px; }
  #Not-found .not-found h2 {
    font-family: "Cabin", sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    margin-top: 0px;
    margin-bottom: 25px; }
  @media only screen and (max-width: 767px) {
    #Not-found .not-found .not-found-404 {
      height: 200px; }
    #Not-found .not-found .not-found-404 h1 {
      font-size: 200px; } }
  @media only screen and (max-width: 480px) {
    #Not-found .not-found .not-found-404 {
      height: 162px; }
    #Not-found .not-found .not-found-404 h1 {
      font-size: 162px;
      height: 150px;
      line-height: 162px; }
    #Not-found .not-found h2 {
      font-size: 16px; } }

.Restore-wallet .close-btn {
  margin-top: 100px; }
  @media (max-width: 767px) {
    .Restore-wallet .close-btn {
      margin-top: 30px; } }
  .Restore-wallet .close-btn .icon {
    width: 38px;
    height: 38px;
    color: #93a8ea;
    cursor: pointer; }

.Restore-wallet .title-container {
  margin-top: 42px;
  margin-bottom: 20px; }
  .Restore-wallet .title-container .title {
    font-size: 2rem;
    color: #93a8ea; }

.Restore-wallet .password-description {
  margin-top: 20px;
  color: #8d8d8d; }

.Restore-wallet .restore-content {
  max-width: 416px;
  margin-bottom: 60px; }
  .Restore-wallet .restore-content .description {
    color: #8d8d8d; }
  .Restore-wallet .restore-content .phrase-container {
    margin-top: 20px; }
    .Restore-wallet .restore-content .phrase-container .phrase-input {
      padding: 30px;
      width: 100%;
      height: 170px;
      resize: none;
      border-radius: 5px;
      box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.07);
      background-color: #ffffff;
      border: 1px solid #93a8ea; }
  .Restore-wallet .restore-content .username-input-container {
    margin-top: 18px; }

.Restore-wallet .main-btn-container {
  margin-top: 28px; }
  .Restore-wallet .main-btn-container .main-btn {
    height: 48px;
    width: 100%; }

.Select-key-file {
  position: relative;
  text-align: center;
  padding-bottom: 40px; }
  .Select-key-file .title-container {
    padding-top: 60px; }
  .Select-key-file .logo-title {
    font-size: 3.5rem; }
  .Select-key-file .welcome-text-container {
    margin-top: 18px;
    max-width: 400px;
    display: inline-block; }
    .Select-key-file .welcome-text-container .welcome-text {
      color: #8d8d8d; }
  .Select-key-file .logo {
    width: 100px;
    height: auto; }
  @media (max-width: 767px) {
    .Select-key-file .logo-title {
      font-size: 2rem; }
    .Select-key-file .logo {
      width: 80px;
      height: auto; } }
  .Select-key-file .button-container {
    margin-top: 22px; }
    .Select-key-file .button-container .button-item {
      width: 250px;
      border-radius: 5px;
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2);
      background-color: #ffffff;
      border: 1px solid #93a8ea;
      text-align: center;
      color: #93a8ea;
      margin-right: 10px;
      margin-left: 10px;
      padding: 34px 12px 34px 12px;
      cursor: pointer; }
      .Select-key-file .button-container .button-item:hover {
        background-color: #93a8ea;
        color: #ffffff; }
      .Select-key-file .button-container .button-item .icon {
        width: 78px;
        height: 78px; }
      .Select-key-file .button-container .button-item .text {
        margin-top: 20px;
        font-size: 1.2rem;
        line-height: 1.25;
        font-family: "Montserrat", sans-serif;
        font-weight: 700; }
      .Select-key-file .button-container .button-item .button-description {
        margin-top: 16px; }
      @media (max-width: 767px) {
        .Select-key-file .button-container .button-item {
          width: 150px; }
          .Select-key-file .button-container .button-item .icon {
            width: 40px;
            height: 40px; }
          .Select-key-file .button-container .button-item .text {
            font-size: 1rem; } }

.app .custom-toast {
  font-size: 16px; }
  .app .custom-toast .Toastify__toast {
    min-height: 45px; }

.app .app-container {
  min-height: calc(100vh - 80px);
  padding-bottom: 40px; }

html {
  font-size: 14px; }

body {
  font-family: "Montserrat", "Noto Sans KR", sans-serif; }

.mono {
  font-family: "Inconsolata", monospace; }

.number {
  font-family: "Spoqa Han Sans", "Spoqa Han Sans JP", "Sans-serif";
  font-weight: bold; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700; }

a {
  color: #2c2c2c; }
  a:hover {
    color: #2c2c2c;
    text-decoration: none; }

.grey {
  color: #8d8d8d; }

.grey2 {
  color: #d8d8d8; }

.grey3 {
  color: #efefef; }

body {
  margin: 0;
  padding: 0;
  background: #f9f9f9; }

.btn-primary,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: white;
  border: 1px solid #93a8ea;
  border-radius: 21px;
  color: #93a8ea;
  font-weight: bold; }
  .btn-primary.square,
  .btn-primary:active.square,
  .btn-primary:visited.square,
  .btn-primary:focus.square {
    border-radius: 0; }
  .btn-primary.selected,
  .btn-primary:active.selected,
  .btn-primary:visited.selected,
  .btn-primary:focus.selected {
    background-color: #93a8ea;
    color: white; }
    .btn-primary.selected:hover,
    .btn-primary:active.selected:hover,
    .btn-primary:visited.selected:hover,
    .btn-primary:focus.selected:hover {
      cursor: default; }
  .btn-primary:hover:enabled,
  .btn-primary:active:hover:enabled,
  .btn-primary:visited:hover:enabled,
  .btn-primary:focus:hover:enabled {
    background-color: #93a8ea;
    border: 1px solid #93a8ea;
    color: white; }
  .btn-primary.reverse,
  .btn-primary:active.reverse,
  .btn-primary:visited.reverse,
  .btn-primary:focus.reverse {
    background-color: #93a8ea;
    color: white;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2); }
    .btn-primary.reverse:hover,
    .btn-primary:active.reverse:hover,
    .btn-primary:visited.reverse:hover,
    .btn-primary:focus.reverse:hover {
      background-color: #6886e2;
      cursor: pointer; }
  .btn-primary:disabled,
  .btn-primary:active:disabled,
  .btn-primary:visited:disabled,
  .btn-primary:focus:disabled {
    background-color: white;
    color: #8d8d8d;
    border: 1px solid #8d8d8d;
    cursor: default; }
    .btn-primary:disabled:hover,
    .btn-primary:active:disabled:hover,
    .btn-primary:visited:disabled:hover,
    .btn-primary:focus:disabled:hover {
      background-color: white;
      color: #8d8d8d;
      border: 1px solid #8d8d8d;
      cursor: default; }

select.form-control {
  border: 1px solid #93a8ea; }

input.form-control[type="text"],
input.form-control[type="password"],
input.form-control[type="number"] {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #93a8ea;
  border-radius: 0;
  font-family: "Spoqa Han Sans", "Spoqa Han Sans JP", "Sans-serif";
  font-weight: bold;
  color: #8d8d8d;
  outline: none;
  box-shadow: none; }
  input.form-control[type="text"]::-webkit-input-placeholder, input.form-control[type="password"]::-webkit-input-placeholder, input.form-control[type="number"]::-webkit-input-placeholder {
    color: #d8d8d8;
    opacity: 0.7; }
  input.form-control[type="text"]::-moz-placeholder, input.form-control[type="password"]::-moz-placeholder, input.form-control[type="number"]::-moz-placeholder {
    color: #d8d8d8;
    opacity: 0.7; }
  input.form-control[type="text"]:-ms-input-placeholder, input.form-control[type="password"]:-ms-input-placeholder, input.form-control[type="number"]:-ms-input-placeholder {
    color: #d8d8d8;
    opacity: 0.7; }
  input.form-control[type="text"]::-ms-input-placeholder, input.form-control[type="password"]::-ms-input-placeholder, input.form-control[type="number"]::-ms-input-placeholder {
    color: #d8d8d8;
    opacity: 0.7; }
  input.form-control[type="text"]::placeholder,
  input.form-control[type="password"]::placeholder,
  input.form-control[type="number"]::placeholder {
    color: #d8d8d8;
    opacity: 0.7; }
  input.form-control[type="text"]:-ms-input-placeholder,
  input.form-control[type="password"]:-ms-input-placeholder,
  input.form-control[type="number"]:-ms-input-placeholder {
    color: #d8d8d8; }
  input.form-control[type="text"]::-ms-input-placeholder,
  input.form-control[type="password"]::-ms-input-placeholder,
  input.form-control[type="number"]::-ms-input-placeholder {
    color: #d8d8d8; }
  input.form-control[type="text"].reverse,
  input.form-control[type="password"].reverse,
  input.form-control[type="number"].reverse {
    border-bottom: 1px solid #f9f9f9;
    color: #f9f9f9; }
    input.form-control[type="text"].reverse::-webkit-input-placeholder, input.form-control[type="password"].reverse::-webkit-input-placeholder, input.form-control[type="number"].reverse::-webkit-input-placeholder {
      color: #f9f9f9;
      opacity: 0.7; }
    input.form-control[type="text"].reverse::-moz-placeholder, input.form-control[type="password"].reverse::-moz-placeholder, input.form-control[type="number"].reverse::-moz-placeholder {
      color: #f9f9f9;
      opacity: 0.7; }
    input.form-control[type="text"].reverse:-ms-input-placeholder, input.form-control[type="password"].reverse:-ms-input-placeholder, input.form-control[type="number"].reverse:-ms-input-placeholder {
      color: #f9f9f9;
      opacity: 0.7; }
    input.form-control[type="text"].reverse::-ms-input-placeholder, input.form-control[type="password"].reverse::-ms-input-placeholder, input.form-control[type="number"].reverse::-ms-input-placeholder {
      color: #f9f9f9;
      opacity: 0.7; }
    input.form-control[type="text"].reverse::placeholder,
    input.form-control[type="password"].reverse::placeholder,
    input.form-control[type="number"].reverse::placeholder {
      color: #f9f9f9;
      opacity: 0.7; }
    input.form-control[type="text"].reverse:-ms-input-placeholder,
    input.form-control[type="password"].reverse:-ms-input-placeholder,
    input.form-control[type="number"].reverse:-ms-input-placeholder {
      color: #f9f9f9; }
    input.form-control[type="text"].reverse::-ms-input-placeholder,
    input.form-control[type="password"].reverse::-ms-input-placeholder,
    input.form-control[type="number"].reverse::-ms-input-placeholder {
      color: #f9f9f9; }

.popover-select-list {
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 700; }
  .popover-select-list li {
    padding: 12px 24px 12px 24px;
    border-bottom: 1px solid #93a7ea4f;
    color: #93a8ea;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer; }
    .popover-select-list li:hover {
      background-color: #93a7ea42; }

.Toastify {
  font-family: "Montserrat", sans-serif;
  font-weight: 700; }
  .Toastify .Toastify__toast--error {
    background-color: #ff5b5b; }
  .Toastify .Toastify__toast--info {
    background-color: #93a8ea; }

.pagination {
  display: inline-flex; }
  .pagination .page-link {
    padding: 0px 8px 0px 8px;
    line-height: 28px;
    color: #b3b3b3;
    border: none;
    font-size: 1.3rem; }
    .pagination .page-link:hover {
      background-color: inherit; }
  .pagination .navigation-icon {
    color: #b3b3b3;
    font-size: 1.3rem;
    border-radius: 2px; }
  .pagination .page-item.active .page-link {
    background-color: inherit;
    color: #93a8ea;
    font-weight: bold; }

